<template>
  <div class="auth">
    <div class="auth-content" v-if="! email_sent">
      <div class="side" v-if="!email_sent">
        <auth-side />
      </div>
      <div class="content">
        <div class="container">
          <div lass="p-0 p-md-4" v-if="step == 1">
            <div class="text-end mt-5 mt-md-0">
              <span class="text-primary">Already have an account? </span>
              <router-link :to="{ name: 'login' }" class="text-primary"
                ><strong>Sign In</strong></router-link
              >
            </div>

            <div class="text-center my-4 mobile-only">
              <router-link :to="{ name: 'home' }"
                ><img src="/logo.svg" alt="logo"
              /></router-link>
            </div>

            <div class="row">
              <div class="col-md-9">
                <div class="card mt-5">
                  <div class="card-body">
                    <h3>Join Us!</h3>
                    <p class="text-muted">
                      To begin this journey, tell us what type of account you’d
                      be opening.
                    </p>
                    <hr />
                    <div
                      class="card shadow mb-3"
                      @click.prevent="step = 2"
                      style="cursor: pointer"
                    >
                      <div class="card-body d-flex">
                        <div>
                          <i
                            class="fa fa-home text-muted"
                            style="font-size: 50px"
                          ></i>
                        </div>
                        <div class="ms-3 centered">
                          <div>
                            <h5>Off-setter Account</h5>
                            <p>Manage your carbon credits activities</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card shadow"
                      @click.prevent="step = 2"
                      style="cursor: pointer"
                    >
                      <div class="card-body d-flex">
                        <div>
                          <i
                            class="fal fa-home text-muted"
                            style="font-size: 50px"
                          ></i>
                        </div>
                        <div class="ms-3 centered">
                          <div>
                            <h5>Carbon credits Account</h5>
                            <p>
                              Individuals manage and buy their carbon emissions
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 p-md-4" v-if="!email_sent && step == 2">
            <div class="text-end mt-5 mt-md-0">
              <span class="text-primary">Already have an account? </span>
              <router-link :to="{ name: 'login' }" class="text-primary"
                ><strong>Sign In</strong></router-link
              >
            </div>

            <div class="text-center my-4 mobile-only">
              <router-link :to="{ name: 'home' }"
                ><img src="/logo.svg" alt="logo"
              /></router-link>
            </div>

            <a href="#" @click.prevent="step = 1"
              ><i class="fa fa-arrow-left me-2"></i>Go Back</a
            >

            <div class="card mt-5 shadow">
              <div class="card-body">
                <h3>Join Us!</h3>
                <p class="text-muted">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Beatae qui cupiditate suscipit! Officiis, tempore eaque?
                </p>
                <hr />
                <form @submit.prevent="submit" id="register_form">
                  <div class="row">
                    <div class="col-md-6 mb-2">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="full-name"
                          placeholder="Full Name"
                          name="name"
                          v-model="user.name"
                        />
                        <label for="full-name">Full Name</label>
                      </div>
                    </div>
                    <div class="col-md-6 mb-2">
                      <div class="form-floating mb-3">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="Email"
                          name="email"
                          v-model="user.email"
                        />
                        <label for="email">Email</label>
                      </div>
                    </div>
                    <div class="col-md-6 mb-2">
                      <password
                        id="password"
                        name="password"
                        v-model="user.password"
                      />
                    </div>
                    <div class="col-md-6 mb-2">
                      <password
                        id="password-confirmation"
                        name="password_confirmation"
                        v-model="user.password_confirmation"
                      />
                    </div>
                    <div class="col-md-12 text-end">
                      <button class="btn btn-primary btn-sm">
                        <i class="fal fa-sign-in-alt me-2"></i>Sign Up
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="email_sent && step == 2" class="centered bg-light h-100-vh">
      <div class="p-0 p-md-4">
        <div class="text-center my-4 mobile-only">
          <router-link :to="{ name: 'home' }"
            ><img src="/logo.svg" alt="logo"
          /></router-link>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-7">
            <div class="card shadow w-100">
              <div class="card-body text-center">
                <h3>Email Sent!</h3>
                <div class="mb-3">
                  <i class="fal fa-envelope text-success" style="font-size: 40px"></i>
                </div>
                <p class="text-muted">
                  We have sent an email to {{ user.email }}. Click on the link to activate your account.
                </p>
                <hr />
                <router-link
                  :to="{ name: 'login' }"
                  class="btn btn-primary w-100"
                  ><i class="fa fa-sign-in me-2"></i>Go to Login</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthSide from "@/components/site/AuthSide";

export default {
  components: {
    AuthSide,
  },

  mounted() {
    if (this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: "dashboard" });
    }
  },

  data() {
    return {
      user: {},
      email_sent: false,
      step: 1,
    };
  },

  methods: {
    submit() {
      this.email_sent = false;
      let data = this.user;
      data.form_id = "register_form";

      this.$axios.post("/api/v1/register", data).then(() => {
        this.email_sent = true;
      });
    },
  },
};
</script>
